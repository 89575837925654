import React, { FC, useMemo } from 'react'
import { Button, Popconfirm, Table, Tooltip } from 'antd'
import dayjs from 'dayjs'

import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'

const Subscription: FC = () => {
  const { isLoading, data, refetch } = useQuery(['querySubscriptions'], async () => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/subscription/list`, {
      headers: {
        token: sessionStorage.getItem('token') as string
      }
    })
    return await response.json()
  })

  const deleteRecord = useMutation(async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/subscription/delete`, {
      method: 'post',
      mode: 'cors',
      body: JSON.stringify({
        id
      }),
      headers: {
        token: sessionStorage.getItem('token') as string
      }
    })
    return await response.json()
  }, {
    onSettled(data) {
      if (data.code === 0) {
        refetch()
      }
    }
  })

  const onDelete = (id) => {
    const request = deleteRecord.mutateAsync(id)

    toast.promise(
      request,
      {
        pending: '正在删除记录',
        success: '删除成功',
        error: {
          render({ data }: any) {
            return  data.message || '网络异常, 请重试' 
          }
        }
      }
    )
  }

  const tableData = useMemo(() => {
    if (!data || data.code !== 0) {
      return []
    }

    return data.data
  }, [data])

  const columns = [
    {
      title: '名',
      dataIndex: 'firstName'
    },
    {
      title: '姓',
      dataIndex: 'lastName'
    },
    {
      title: '邮箱',
      dataIndex: 'email'
    },
    {
      title: 'Telegram',
      dataIndex: 'telegram'
    },
    {
      title: '项目名称',
      dataIndex: 'projectName'
    },
    {
      title: '预算($)',
      dataIndex: 'budget'
    },
    {
      title: '项目简介',
      dataIndex: 'describtion',
      render(_, row) {
        return (
          <Tooltip placement="top" title={row.describtion}>
            <span style={{ cursor: 'pointer' }}>{row.describtion.substr(0, 10)}{row.describtion.length > 10 ? '...' : ''}</span>
          </Tooltip>
        )
      },
    },
    {
      title: '提交时间',
      dataIndex: 'createDate',
      render(_, row) {
        return dayjs(row.createDate).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: '操作',
      render(_, row) {
        return (
          <Popconfirm title='确定删除此订阅吗' onConfirm={() => onDelete(row._id)}>
            <Button size='small' danger>删除</Button>
          </Popconfirm>
        )
      }
    },
  ]

  return (
    <div style={{
      padding: '20px'
    }}>
      <Table columns={columns} loading={isLoading} dataSource={tableData} bordered />
    </div>
  )
}

export default Subscription
