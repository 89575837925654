import React from 'react'
import { ConfigProvider } from 'antd'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import zhCN from 'antd/es/locale/zh_CN'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Login from './views/Login'
import Subscription from './views/Subscription'
import NotFound from './views/NotFound'

const client: QueryClient = new QueryClient()

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/subscription',
    element: <Subscription />
  },
  {
    path: '',
    element: <NotFound />
  }
])

const App: React.FC = () => {
  return (
    <>
      <ConfigProvider locale={zhCN}>
        <QueryClientProvider client={client}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </ConfigProvider>
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme='dark'
      />
    </>
  )
}

export default App
