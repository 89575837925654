import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import { Button, Form, Input } from 'antd'

import { useMutation } from '@tanstack/react-query'

const Login: FC = () => {
  const navigate = useNavigate()
  const [ form ] = Form.useForm()

  const mutate = useMutation(
    async (data: any) => {
      const result = await fetch(`${process.env.REACT_APP_API_BASE}/login`, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(data)
      })

      const json = await result.json()

      if (json.code !== 0) {
        throw new Error(json.message)
      }

      return json
    },
    {
      onSuccess:(res) => {
        console.log(res)
        if (res.code === 0) {
          sessionStorage.setItem('token', res.token)
          setTimeout(() => {
            navigate('/subscription')
          }, 3000)
        }
      }
    }
  )

  const onFinish = (data) => {
    const request = mutate.mutateAsync(data)

    toast.promise(
      request,
      {
        pending: '登录中, 请稍后',
        success: '登录成功, 即将为您跳转',
        error: {
          render({ data }: any) {
            return  data.message || '网络异常, 请重试' 
          }
        }
      }
    )
  }

  return (
    <div className='login-page'>
      <div className='login-form'>
        <Form requiredMark={false} form={form} onFinish={onFinish}>
          <Form.Item
            label="账户"
            name="username"
            rules={[{ required: true, message: '请输入用户名' }]}
          >
            <Input width={300} />
          </Form.Item>
          <Form.Item
            label="密码"
            name="password"
            rules={[{ required: true, message: '请输入密码' }]}
          >
            <Input.Password width={300} />
          </Form.Item>
          <Button htmlType='submit' block>登录</Button>
        </Form>
      </div>
    </div>
  )
}

export default Login
